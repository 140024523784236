import React, { Component } from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
import DataScienceImg from "./datascience/DataScienceImg";
import FullStackImg from "./web/FullStackImg";
import DesignImg from "./ui/DesignImg";

function GetSkillSvg(props) {
  if (props.fileName === "DataScienceImg")
    return <DataScienceImg theme={props.theme} />;
  else if (props.fileName === "FullStackImg")
    return <FullStackImg theme={props.theme} />;
  return <DesignImg theme={props.theme} />;
}

class SkillSection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div>
        {skills.data.map((skill) => {
          if(skill.title !== 'UI/UX Design'){
            return (
              <div className={skill.title == 'UI/UX Design' ? "ui-design-div skills-main-div" : "skills-main-div"}>
               
                <Fade left duration={2000}>
                  <div className="skills-image-div">
                    <GetSkillSvg fileName={skill.fileName} theme={theme} />
                  </div>
                </Fade>
                <div className="skills-text-div">
                  <Fade right duration={1000}>
                    <h1 className="skills-heading" style={{ color: theme.blue }}>
                      {skill.title}
                    </h1>
                  </Fade>
                  <Fade right duration={1500}>
                    <SoftwareSkill logos={skill.softwareSkills} />
                  </Fade>
                  <Fade right duration={2000}>
                    <div>
                      {skill.skills.map((skillSentence) => {
                        return (
                          <p
                            className="subTitle skills-text"
                            style={{ color: theme.text }}
                          >
                            {skillSentence}
                          </p>
                        );
                      })}
                    </div>
                  </Fade>
                </div>
              </div>
            );
          } 
          
          if(skill.title == 'UI/UX Design'){

            return (
              <div className="ui-design-div skills-main-div">
               
                <Fade right duration={2000}>
                  <div className="skills-image-div">
                    <GetSkillSvg fileName={skill.fileName} theme={theme} />
                  </div>
                </Fade>
                <div className="skills-text-div">
                  <Fade left duration={1000}>
                    <h1 className="skills-heading" style={{ color: theme.blue }}>
                      {skill.title}
                    </h1>
                  </Fade>
                  <Fade left duration={1500}>
                    <SoftwareSkill logos={skill.softwareSkills} />
                  </Fade>
                  <Fade left duration={2000}>
                    <div>
                      {skill.skills.map((skillSentence) => {
                        return (
                          <p
                            className="subTitle skills-text"
                            style={{ color: theme.text }}
                          >
                            {skillSentence}
                          </p>
                        );
                      })}
                    </div>
                  </Fade>
                </div>
              </div>
            );


          }

      
        })}
      </div>
    );
  }
}

export default SkillSection;
